// @generated
// THIS FILE WAS AUTOMATICALLY GENERATED AND SHOULD NOT BE EDITED
/* eslint-disable */

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    ClassAndShindig: ['Class', 'Shindig'],
    CoachingOutcomeUnion: [
      'JobOfferInfo',
      'SchoolAdmissionInfo',
      'TestScoreV2',
    ],
    CoachingSessionContent: ['Class', 'OneOnOneSession'],
    CustomerCreditSource: ['Order', 'Sku'],
    CustomerGoalUnion: [
      'CustomerCareerGoal',
      'CustomerSchoolGoal',
      'CustomerTestGoal',
    ],
    FeaturedSectionUnion: [
      'CareerCategoryFeaturedSection',
      'SchoolCategoryFeaturedSection',
    ],
    OrganizationOutcomeUnion: ['Company', 'School'],
    OutcomeSummaryUnion: [
      'CareerOutcomeSummary',
      'SchoolOutcomeSummary',
      'TestOutcomeSummary',
    ],
    PackageTargetUnion: ['Company', 'School'],
    UserOrGuest: ['Guest', 'User'],
  },
};
export default result;

import {
  CancelReason,
  CoachingRelationshipStatus,
  CoachStatus,
  GoalName,
  type Scalars,
  SkuTier,
} from '../__generated-gql-types__/globalTypes';

/**
 * Check whether the current env is server, as opposed to browser
 * @returns whether the current env is server
 */
export const isServer = typeof window === 'undefined';

export const __DEV__ = process.env.NODE_ENV !== 'production';
export const __STAGING__ =
  process.env.NEXT_PUBLIC_APPLICATION_ENV !== 'production';

export const HTTP_RE = new RegExp('^https?://');

export const NUMBER_OF_HOURS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const NUMBER_OF_SCHOOLS = [3, 4, 5, 6, 7, 8, 9, 10];

export const LELAND_DOMAIN =
  __DEV__ || __STAGING__ ? '.leland-staging.com' : '.joinleland.com';

export const COACH_SITE_URL =
  process.env.NEXT_PUBLIC_LELAND_COACH_URL ??
  (__DEV__
    ? 'https://coach.local.leland-staging.com:3000'
    : __STAGING__
      ? 'https://coach.leland-staging.com'
      : 'https://coach.joinleland.com');
export const AUTH_REDIRECT_URI = isServer
  ? ''
  : `${window.location.origin}/auth/callback`;
export const APPLICANT_SITE_URL =
  process.env.NEXT_PUBLIC_LELAND_APPLICANT_URL ?? 'https://www.joinleland.com';
export const LIBRARY_URL = `${APPLICANT_SITE_URL}/library`;
export const COACH_LIBRARY_URL =
  'https://joinleland.notion.site/joinleland/Coach-s-Corner-Your-Guide-to-Coaching-on-Leland-17dc34f0a40e458b90d521b31440412d';
export const LEGAL_URL = `${APPLICANT_SITE_URL}/legal`;
export const BASE_VOUCH_URL = `${APPLICANT_SITE_URL}/vouch`;
export const COACH_INTRODUCTION_VIDEO_URL =
  'https://joinleland.notion.site/How-to-Set-Up-Your-Coach-Profile-The-Introduction-Video-b647557ed92e42bbb0d32dbf7002991d';
export const COACH_PAYOUTS_ARTICLE_URL =
  'https://joinleland.notion.site/How-do-payouts-work-bbfad29c13a745f189f0281f1445e707';
export const COACH_TRIAL_SESSION_ARTICLE_URL =
  'https://joinleland.notion.site/Trial-Sessions-and-Partnerships-114a84f4c7d28058bb46e76ad2677de6';
export const SUPPORT_URL = `${APPLICANT_SITE_URL}/support`;
export const BASE_ARTICLE_URL = `${LIBRARY_URL}/a`;
export const BASE_RELEASE_NOTES_URL =
  'https://joinleland.notion.site/What-s-new-for-coaches-a3941262cbfb4466a72f1606ba8fedce';
export const COACH_COMMUNITY_SLACK_URL =
  'https://join.slack.com/t/lelandcoaches/shared_invite/zt-2v6pwf8pt-QYtVAyK2ujG22LxkRrhLPQ';
export const COACH_ONBOARDING_SESSION_URL =
  'https://calendly.com/erikamahterian/leland-coach-onboarding';
export const COACH_MOBILE_APP_APP_STORE_URL =
  'https://apps.apple.com/us/app/leland-for-coaches/id6444394433';
export const LELAND_PLUS_CONTENT_REQUESTS_URL =
  'https://docs.google.com/spreadsheets/d/1K6yEJjJJhAfJ3s3OGr9YNdYsYAKD-K5pO7f9qZRQlJo/edit?usp=sharing';
export const HOST_AN_EVENT_URL = 'https://joinleland.typeform.com/to/kwbI7vlQ';
export const INTRO_CALL_CONVERSION_DOC =
  'https://joinleland.notion.site/Running-Effective-Intro-Calls-0343ad868e4d4b1ab2b25c10a0acd1e9';

export const MAXIMUM_CATEGORY_LIMIT = 6;

export const DEFAULT_ERROR_MESSAGE =
  'Oops! Something went wrong. Please try again. If the issue persists, contact Leland support at support@joinleland.com.';

export const DEFAULT_CLASS_IMAGE = '/images/class-placeholder-image.png';

export const ONBOARDED_COACH_STATUSES: CoachStatus[] = [
  CoachStatus.LIVE,
  CoachStatus.DEACTIVATED,
  CoachStatus.DISABLED,
];

export const APPROVED_COACH_STATUSES: CoachStatus[] = [
  CoachStatus.APPROVED,
  ...ONBOARDED_COACH_STATUSES,
];

export const COACHING_RELATIONSHIP_STATUSES: CoachingRelationshipStatus[] =
  Object.values(CoachingRelationshipStatus);

export enum CoachingRelationshipFlag {
  ARCHIVED = 'ARCHIVED',
  BLOCKED = 'BLOCKED',
  PENDING_ORDER = 'PENDING_ORDER',
  PENDING_INTRO_MESSAGE = 'PENDING_INTRO_MESSAGE',
  PENDING_INTRO_REQUEST = 'PENDING_INTRO_REQUEST',
}

export const COACHING_RELATIONSHIP_FLAGS: CoachingRelationshipFlag[] =
  Object.values(CoachingRelationshipFlag);

export const GOAL_NAMES: GoalName[] = Object.values(GoalName);

export const CANCEL_STATUS_TOOL_TIP_MESSAGE: Record<CancelReason, string> = {
  [CancelReason.EXPIRED]: 'Payment has expired.',
  [CancelReason.REQUESTED_BY_CUSTOMER]: 'Requested by client.',
};

export const UNSURE_SCHOOL_NUMBER = -1;

export const POSITIVE_INT_PATTERN = /^[1-9]\d*$/;
export const EMAIL_FUZZY_REGEX = /^.+@.+$/;

export const ALL_SERVICES_COUPON_TYPE = 'All Services';

export enum MAIN_OPS_CLASS_FILTER_VALUES {
  SESSIONS = 'sessions',
  PENDING_CLASSES = 'pending',
  APPROVED_CLASSES = 'approved',
}

export enum SECONDARY_OPS_CLASS_FILTER_VALUES {
  UPCOMING_SESSIONS = 'Upcoming sessions',
  PAST_SESSIONS = 'Past sessions',
  NEEDS_ATTENTION = 'Needs attention',
  ALL_PENDING_CLASSES = 'All pending classes & events',
  NEW_CLASSES = 'New classes & events',
  UPDATED_CLASSES = 'Updated classes & events',
  LONG_WAITLISTS = 'Long waitlists',
  ALL_APPROVED_CLASSES = 'All approved classes & events',
  CLASSES_WITH_UPCOMING_SESSIONS = 'Classes with upcoming sessions',
  CLASSES_WITH_NO_SCHEDULED_SESSIONS = 'Classes with no scheduled sessions',
}
type ExperienceLevelMap = Record<number, string>;
export const GENERIC_EXPERIENCE_LEVELS: ExperienceLevelMap = Object.freeze({
  1: 'Intern',
  3: 'Analyst',
  4: 'Associate',
  5: 'Manager',
  6: 'Director',
  8: 'Vice President',
  10: 'Executive',
});

export const SPECIFIC_EXPERIENCE_LEVELS: Record<
  Scalars['CategoryUrn']['output'],
  ExperienceLevelMap
> = Object.freeze({
  'urn:category:product-management': {
    1: 'Product Manager Intern',
    2: 'Associate Product Manager',
    3: 'Product Manager',
    5: 'Group Product Manager',
    6: 'Director of Product',
    8: 'VP of Product',
    10: 'Chief Product Officer',
  },
  'urn:category:management-consulting': {
    1: 'Intern',
    3: 'Analyst',
    4: 'Associate',
    5: 'Consultant',
    6: 'Case Team Leader',
    8: 'Associate Partner',
    10: 'Partner or Managing Director',
  },
  'urn:category:investment-banking': {
    1: 'Intern',
    3: 'Analyst',
    4: 'Associate',
    6: 'Senior Associate',
    8: 'Vice President',
    10: 'Partner or Managing Director',
  },
  'urn:category:private-equity': {
    1: 'Intern',
    3: 'Analyst',
    4: 'Associate',
    6: 'Senior Associate',
    8: 'Vice President',
    10: 'Partner or Managing Director',
  },
  'urn:category:venture-capital': {
    1: 'Intern',
    3: 'Analyst',
    4: 'Associate',
    6: 'Senior Associate',
    7: 'Angel Investor',
    8: 'Principal',
    10: 'Partner',
  },
});

export const RETURN_TO_KEY = 'returnTo';
export const FIRST_NAME_KEY = 'firstName';
export const LAST_NAME_KEY = 'lastName';

export const MIN_CLASS_PRICE = 5;
export const MAX_CLASS_PRICE = 100;

export const LINKED_IN_REGEX = new RegExp(
  '^https?://(\\w+\\.)?linkedin\\.com/in/[A-z0-9_-]{3,}/?',
);

export const DEFAULT_PACKAGE_IMAGE = '/images/package-placeholder-image.png';

export const SUBSCRIBER_DUMMY_COACH_URN = 'urn:coach:60b2cb327b8ead4a78c16fc0';

export const TYPEFORM_URLS = {
  SUPPORT: 'https://joinleland.typeform.com/to/TY5OgGwK',
  APPLY_TIER_UPGRADE: 'https://joinleland.typeform.com/to/ctLSXQyA',
  SUGGEST_PACKAGE: 'https://joinleland.typeform.com/to/QoRjyUEL',
};

export const COMPREHENSIVE_PACKAGE_NAME = 'Comprehensive';

export const TAKE_HOME_ESTIMATES = {
  [SkuTier.BASE]: 'between $50 - $60/hr',
  [SkuTier.PRO]: 'between $100 - $120/hr',
  [SkuTier.ELITE]: 'between $200 - $235/hr',
  [SkuTier.ELITE_PLUS]: 'between $300 - $335/hr',
};

import {
  createContext,
  type PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

const MISSING_SAVING_INDICATOR_CONTEXT_PROVIDER =
  'You forgot to wrap your app in <SavingIndicatorContextProvider>';

interface SavingIndicatorContext {
  isSaving: boolean;
  isSaveSuccess: boolean;
  setIsSaving: () => void;
  setIsSaveSuccess: (isSuccess: boolean) => void;
}

export const SavingIndicatorContext = createContext<SavingIndicatorContext>({
  get isSaving(): never {
    throw new Error(MISSING_SAVING_INDICATOR_CONTEXT_PROVIDER);
  },
  get isSaveSuccess(): never {
    throw new Error(MISSING_SAVING_INDICATOR_CONTEXT_PROVIDER);
  },
  get setIsSaving(): never {
    throw new Error(MISSING_SAVING_INDICATOR_CONTEXT_PROVIDER);
  },
  get setIsSaveSuccess(): never {
    throw new Error(MISSING_SAVING_INDICATOR_CONTEXT_PROVIDER);
  },
});

export const useSavingIndicatorContext: () => SavingIndicatorContext = () =>
  useContext<SavingIndicatorContext>(SavingIndicatorContext);

const SavingIndicatorContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [isSavingDirect, setIsSavingDirect] = useState(false);
  const [isSavingTimeout, setIsSavingTimeout] = useState(false);
  const [isSaveSuccessDirect, setIsSaveSuccessDirect] = useState(false);
  const saveSuccessTimeout = useRef<number>(null);

  const setIsSaving = useCallback(() => {
    setIsSavingDirect(true);
    setIsSaveSuccessDirect(false);
    setIsSavingTimeout(true);

    if (saveSuccessTimeout.current) {
      clearTimeout(saveSuccessTimeout.current);
    }

    saveSuccessTimeout.current = window.setTimeout(
      () => setIsSavingTimeout(false),
      2000,
    );
  }, []);

  const setIsSaveSuccess = useCallback((isSuccess: boolean) => {
    setIsSavingDirect(false);
    setIsSaveSuccessDirect(isSuccess);
    if (!isSuccess) {
      if (saveSuccessTimeout.current) {
        clearTimeout(saveSuccessTimeout.current);
      }
      setIsSavingTimeout(false);
    }
  }, []);

  useEffect(() => {
    if (isSaveSuccessDirect && !isSavingTimeout) {
      setTimeout(() => setIsSaveSuccessDirect(false), 2000);
    }
  }, [isSaveSuccessDirect, isSavingTimeout]);

  return (
    <SavingIndicatorContext.Provider
      value={{
        isSaving: isSavingDirect || isSavingTimeout,
        isSaveSuccess:
          isSaveSuccessDirect && !isSavingDirect && !isSavingTimeout,
        setIsSaving,
        setIsSaveSuccess,
      }}
    >
      {children}
    </SavingIndicatorContext.Provider>
  );
};

export default SavingIndicatorContextProvider;

import { useApolloClient } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { MINUTE } from '@leland-dev/leland-ui-library';
import { useRouter } from 'next/router';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { CoachStatus } from '../__generated-gql-types__/globalTypes';
import { getFirstErrorMessage } from '../utils/apollo';
import { trackGtm } from '../utils/gtm';
import { getUrlObject } from '../utils/url';

import {
  AuthContextDocument,
  type AuthContextUserFragment,
  type SignupMutationVariables,
  useAuthContextQuery,
  useLoginMutation,
  useLogoutMutation,
  useRequestLoginVerificationCodeMutation,
  useRequestSignupVerificationCodeMutation,
  useSignupAsCoachMutation,
  useSignupMutation,
} from './__generated-gql-types__/AuthContext.generated';

interface AuthContext {
  currentUser: Possible<AuthContextUserFragment>;
  errorLoadingUser?: Error;
  isLoadingUser: boolean;
  isImpersonating: boolean;
  /**
   * In most cases, current user would automatically be updated by Apollo, unless the cached one is null.
   */
  setCurrentUser: (data: AuthContextUserFragment) => void;
  redirectToVerifyEmail: (redirectUrl: string) => Promise<boolean>;
  isEmailVerified: boolean | undefined;
  trackSignUp: (userId: string, coachId?: string) => void;
  subscribeToAuthChanges: (interval?: number) => Optional<() => void>;
}

const missingAuthProvider = 'You forgot to wrap your app in <AuthProvider>';
const NEW_CUSTOMER_VERIFY_EMAIL_BUFFER = 12 * 3600 * 1000; // 12 hr

export const AuthContext = createContext<AuthContext>({
  get currentUser(): never {
    throw new Error(missingAuthProvider);
  },
  get errorLoadingUser(): never {
    throw new Error(missingAuthProvider);
  },
  get isLoadingUser(): never {
    throw new Error(missingAuthProvider);
  },
  get isImpersonating(): never {
    throw new Error(missingAuthProvider);
  },
  get setCurrentUser(): never {
    throw new Error(missingAuthProvider);
  },
  get redirectToVerifyEmail(): never {
    throw new Error(missingAuthProvider);
  },
  get isEmailVerified(): never {
    throw new Error(missingAuthProvider);
  },
  get trackSignUp(): never {
    throw new Error(missingAuthProvider);
  },
  get subscribeToAuthChanges(): never {
    throw new Error(missingAuthProvider);
  },
});

const trackSignUp = (userId: string, coachId?: string) => {
  trackGtm('coachSignUp', { userId, ...(coachId ? { coachId } : {}) });
};

export const useAuth: () => AuthContext = () =>
  useContext<AuthContext>(AuthContext);

export const useLogout = (): {
  logout: () => Promise<void>;
  logoutLoading: boolean;
} => {
  const apolloClient = useApolloClient();
  const { logout: logoutWithAuth0 } = useAuth0();
  const [logout, { loading: logoutLoading }] = useLogoutMutation();

  return useMemo(
    () => ({
      logout: async () => {
        await logout();
        apolloClient.writeQuery({
          query: AuthContextDocument,
          data: { user: null },
        });
        await logoutWithAuth0({
          logoutParams: {
            returnTo: window.location.origin,
          },
        });
      },
      logoutLoading,
    }),
    [apolloClient, logout, logoutLoading, logoutWithAuth0],
  );
};

interface RequestVerificationCodeReturn {
  requestVerificationCode: (email: string) => Promise<void>;
  verificationCodeLoading: boolean;
}

export const useLogin = (): RequestVerificationCodeReturn & {
  login: (email: string, code: string) => Promise<AuthContextUserFragment>;
  loginLoading: boolean;
} => {
  const apolloClient = useApolloClient();
  const [login, { loading: loginLoading }] = useLoginMutation();
  const [requestVerificationCode, { loading: verificationCodeLoading }] =
    useRequestLoginVerificationCodeMutation();

  return useMemo(
    () => ({
      login: async (email: string, code: string) => {
        const { data, errors } = await login({
          variables: { email, code },
        });
        if (!data || errors?.length) {
          console.warn(errors);
          throw new Error(getFirstErrorMessage(errors));
        }

        trackGtm('login', {
          userId: data.login.id,
          ...(data.login.coach?.id ? { coachId: data.login.coach?.id } : {}),
        });

        apolloClient.writeQuery({
          query: AuthContextDocument,
          data: { user: data.login },
        });

        return data.login;
      },
      loginLoading,
      requestVerificationCode: async (email: string) => {
        const { data, errors } = await requestVerificationCode({
          variables: { email },
        });
        if (!data?.requestLoginSecurityCode || errors?.length) {
          console.warn(errors);
          throw new Error(getFirstErrorMessage(errors));
        }
      },
      verificationCodeLoading,
    }),
    [
      apolloClient,
      login,
      loginLoading,
      requestVerificationCode,
      verificationCodeLoading,
    ],
  );
};

export const useSignup = (): RequestVerificationCodeReturn & {
  signup: (vars: SignupMutationVariables) => Promise<AuthContextUserFragment>;
  signupLoading: boolean;
  signupAsCoach: () => Promise<AuthContextUserFragment>;
  signupAsCoachLoading: boolean;
} => {
  const apolloClient = useApolloClient();
  const [signup, { loading: signupLoading }] = useSignupMutation();
  const [signupAsCoach, { loading: signupAsCoachLoading }] =
    useSignupAsCoachMutation();
  const [requestVerificationCode, { loading: verificationCodeLoading }] =
    useRequestSignupVerificationCodeMutation();

  return useMemo(
    () => ({
      signup: async (variables: SignupMutationVariables) => {
        const { data, errors } = await signup({ variables });

        if (!data || errors) {
          console.warn(errors);
          throw new Error(getFirstErrorMessage(errors));
        }

        apolloClient.writeQuery({
          query: AuthContextDocument,
          data: { user: data.signup },
        });

        trackSignUp(data.signup.id, data.signup.coach?.id);

        return data.signup;
      },
      signupLoading,
      signupAsCoach: async () => {
        const { data, errors } = await signupAsCoach();

        if (!data || errors) {
          console.warn(errors);
          throw new Error(getFirstErrorMessage(errors));
        }

        apolloClient.writeQuery({
          query: AuthContextDocument,
          data: { user: data.signupAsCoach },
        });

        trackSignUp(data.signupAsCoach.id, data.signupAsCoach.coach?.id);

        return data.signupAsCoach;
      },
      signupAsCoachLoading,
      requestVerificationCode: async (email: string) => {
        const { data, errors } = await requestVerificationCode({
          variables: { email },
        });
        if (!data?.requestSignupSecurityCode || errors?.length) {
          console.warn(errors);
          throw new Error(getFirstErrorMessage(errors));
        }
      },
      verificationCodeLoading,
    }),
    [
      apolloClient,
      requestVerificationCode,
      signup,
      signupAsCoach,
      signupAsCoachLoading,
      signupLoading,
      verificationCodeLoading,
    ],
  );
};

interface Props {
  children: React.ReactNode;
}

export default function AuthContextProvider({
  children,
}: Props): React.ReactElement {
  const router = useRouter();
  const apolloClient = useApolloClient();
  const { data, loading, error, startPolling, stopPolling } =
    useAuthContextQuery({ fetchPolicy: 'network-only', errorPolicy: 'all' });
  const { getIdTokenClaims } = useAuth0();
  const [isEmailVerified, setEmailVerified] = useState<boolean | undefined>();

  const { logout } = useLogout();

  const redirectToVerifyEmail = useCallback(
    async (redirectUrl: string) => {
      if (!data?.user || loading) {
        return false;
      }
      const idTokenClaims = await getIdTokenClaims();
      setEmailVerified(idTokenClaims?.email_verified);
      if (
        idTokenClaims &&
        !idTokenClaims.email_verified &&
        !router.pathname.startsWith('/auth/') &&
        !router.pathname.startsWith('/apply') &&
        Date.now() - data.user.createdAt >= NEW_CUSTOMER_VERIFY_EMAIL_BUFFER
      ) {
        await router.replace(
          getUrlObject('/auth/verify', {
            redirect_url: redirectUrl,
          }),
        );
        return true;
      }
      return false;
    },
    [getIdTokenClaims, router, data?.user, loading],
  );

  const context: AuthContext = useMemo(
    () => ({
      currentUser: data?.user,
      errorLoadingUser: error,
      isLoadingUser: loading,
      isImpersonating: data?.user?.impersonator != null,
      setCurrentUser: (data: AuthContextUserFragment) => {
        apolloClient.writeQuery({
          query: AuthContextDocument,
          data: { user: data },
        });
      },
      redirectToVerifyEmail: redirectToVerifyEmail,
      isEmailVerified: isEmailVerified,
      trackSignUp,
      subscribeToAuthChanges: (interval = 1 * MINUTE) => {
        startPolling(interval);
        return stopPolling;
      },
    }),
    [
      apolloClient,
      data?.user,
      error,
      loading,
      redirectToVerifyEmail,
      isEmailVerified,
      startPolling,
      stopPolling,
    ],
  );

  useEffect(() => {
    if (data?.user?.id) {
      void redirectToVerifyEmail(router.asPath);
      const idsObj: Record<string, string> = {
        userId: data.user.id,
      };
      if (data.user.coach?.id) {
        idsObj.userCoachId = data.user.coach.id;
      }
      trackGtm(undefined, idsObj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.user?.coach?.id, data?.user?.id]);

  useEffect(() => {
    // if coach is disabled, log them out immediately
    if (data?.user?.coach?.status === CoachStatus.DISABLED) {
      void logout();
    }
  }, [logout, data?.user?.coach?.status]);

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
}
